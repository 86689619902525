import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background:'rgba(0,0,0,0.6)'
    },
}));

const LoaderSpiner = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    return (
        <>
            <Backdrop className={`${classes.backdrop} contentLoader `} open={open}>
               <div className='d-flex align-items-center flex-column'>
               <img src={localStorage.getItem('logoUrl')} className='loader_img'/>
                <CircularProgress color="inherit" />
               </div>
            </Backdrop>
        </>
    );
}

export default LoaderSpiner;