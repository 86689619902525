import React from 'react';
import {Link} from 'react-router-dom';

const Social = () => {
    return (
        <>
            <div className="social">
                <h5>Connect with us</h5>
                <ul className="mb-0 border-bottom-0">
                    <li className="ml-0">
                        <a href="https://www.facebook.com/amritaliveott" target="_blank" className="transition" ><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/LiveAmrita" target="_blank" className="transition"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/amritalive/channel/" target="_blank" className="transition"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    </li>
                   <li>
                        <a href="https://www.youtube.com/c/amritalive/videos" target="_blank" className="transition" ><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                    </li>
                   
                
                </ul>
            </div>
        </>
    );
}


export default Social;