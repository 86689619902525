import React from "react";
import axios from "axios";
import { firebaseAnalytics } from "../../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link, Redirect } from "react-router-dom";
import ModernDatepicker from 'react-modern-datepicker';
import dayjs from 'dayjs';
import app_icon from '../../assests/images/app_icon.png';
// import dayjs from 'dayjs';
import {
  COUNTRYCODE,
  PARTNER_ID,
  LOGIN,
  MULTIDEVICELOGOUT,
  UUID,
  SIGNUP,
  SIGNUPVERIFY,
  GET_TOKEN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FORGETVERIFY,
  COUNTRYLIST
} from "../../url";
import $ from "jquery";
import Loader from "../../component/loader/loader_spiner.js";
import nacl from "tweetnacl";
import utils from "tweetnacl-util";
import { toast, cssTransition, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Logoimg from "../../assests/images/login/amrita_livelogo.png";
import MailIcn from "../../assests/images/login/mail.png";
import PswrdIcn from "../../assests/images/login/password.png";
import CntryIcn from "../../assests/images/login/country.png";
import DateIcn from "../../assests/images/login/date.png";
import UserIcn from "../../assests/images/login/user1.png";
import bckIcn from "../../assests/images/login/arrow.png"
import rspnsvImage from "../../assests/images/login/rspnsv-bg.jpg"
import Moment from 'react-moment';
import UAParser from 'ua-parser-js';

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  duration: 750,
});
const clientId =
  "820316952394-idjpoacrpglmmelfa1dv9t4p6udoe686.apps.googleusercontent.com";
let timer;
let forgettimer;

const parser = new UAParser();
const getOS = parser.getOS().name;
// const getBrowser = parser.getBrowser().name;
// const getEngine = parser.getEngine().name;
// const getDeviceModel = parser.getDevice().model;

// alert(getOS)

class Login extends React.Component {
  multiDeviceRef = [];
  constructor(props) {
    super(props);
    this.newpassword = React.createRef();
    this.forgetRef = React.createRef();
    this.inputEmail = React.createRef();
    // this.multiDeviceRef = React.createRef();
    this.state = {
      isLogin: true,
      isSignup: false,
      isLoading: false,
      isForget: false,
      isResetPassword: false,
      //isEmail: "",
      startDate: "",
      isProcessing: false,
      emailInputState: "",
      sendForOTP: false,
      isOTP: "",
      emailOTP1: "",
      emailOTP2: "",
      emailOTP3: "",
      emailOTP4: "",
      otp_ele_length: 0,
      otp_check: 0,
      otp_forgotcheck: false,
      OTP_time: 180,
      otpExpire: false,
      isforgetsendOTP: false,
      isFORGETOTP: "",
      forgetPassEmailOTP1: "",
      forgetPassEmailOTP2: "",
      forgetPassEmailOTP3: "",
      forgetPassEmailOTP4: "",
      forgetotp_ele_length: 0,
      forgetotp_check: 0,
      FORGETOTP_time: 180,
      forgetotpExpire: false,
      window_width: window.screen.width,
      detect_device: "",
      isOpenModal: false,
      isKnowMoreModal: false,
      emailverify: false,
      forgetOTPSENDBUTTON: true,
      currentActiveDevice: "",
      loginDevices: [],
      loginToken: null,
      limitMessage: "",
      deviceLimit: 1,
      loginDevicesLength: 1
    };
    // this.windowLoad = this.windowLoad.bind(this)
  }

  encryptPassword = (data) => {
    const encodeBase64 = utils.encodeBase64;
    // Our nonce must be a 24 bytes Buffer (or Uint8Array)
    const nonce = nacl.randomBytes(24);
    // Our secret key must be a 32 bytes Buffer (or Uint8Array)
    const secretKey = Buffer.from("useonlymysecretkeyonplanetcast17", "utf8");
    // Make sure your data is also a Buffer of Uint8Array
    const secretData = Buffer.from(`${data}`, "utf8");
    const encrypted = nacl.secretbox(secretData, nonce, secretKey);
    // We can now store our encrypted result and our nonce somewhere
    const result = `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`;
    return result;
  };

  async componentDidMount() {
    document.body.classList.remove('darkHdr');
    document.body.classList.remove('blckThem');
    document.body.classList.remove('topspace');
    document.body.classList.add('heardr-rmvo');
    localStorage.setItem('themeColor', 'whiteTheme');
    setInterval(() => {
      const themeColor = localStorage.getItem('themeColor');
      if (themeColor === "blckThem") {
        document.body.classList.add('blckThem');
      }
      else {
        document.body.classList.remove('blckThem');
      }
    }, 50);
    let ins = this
    let location = window.location.pathname
    if (location === '/signup') {
      ins.setState({ isLogin: false, isSignup: true });
    }
    window.gtag("event", "page_view", {
      "Page title": "LOGIN",

    });
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: "866919227443956", //Change with your Facebook app id
        autoLogAppEvents: true,
        xfbml: true,
        version: "v10.0",
      });

      window.FB.Event.subscribe("auth.statusChange", (response) => {
        if (response.authResponse) {
          this.checkLoginState();
        } else {
          console.log(
            "[FacebookLoginButton] User cancelled login or did not fully authorize."
          );
        }
      });
    };
    //detecting device ios and android
    if (getOS === "Android" || getOS === "iOS") {
      // const getDeviceModel = parser.getDevice().model;
      const getDeviceModel = parser.getBrowser().name;
      // alert("mobile" + getDeviceModel)
      this.setState({ detect_device: getOS, currentActiveDevice: getDeviceModel });

    } else {
      // web plateform
      const getBrowser = parser.getBrowser().name;
      // const getEngine = parser.getEngine().name;
      // alert("web" + getBrowser)
      this.setState({ detect_device: getOS, currentActiveDevice: getBrowser });
    }
  }


  componentDidUpdate(prevProps, prevState) {
    //   let ins = this
    //   let location = window.location.pathname
    //      if(location === '/signup' && location !== undefined){ 
    //        if(prevState.isLogin !==false) {
    //          
    //         // ins.setState({ isLogin: false, isSignup: true });
    //        }
    //     // //  // // debugger
    //     // ins.setState({ isLogin: false, isSignup: true });
    //     // //  // // debugger
    // }
    //for signup password otp
    if (
      prevState.otp_ele_length !== this.state.otp_ele_length &&
      this.state.otp_ele_length === 4
    ) {
      this.checkOTPNo();
    }

    //for forget password otp
    if (
      prevState.forgetotp_ele_length !== this.state.forgetotp_ele_length &&
      this.state.forgetotp_ele_length === 4
    ) {
      this.checkFORGETOTPNo();
    }
  }

  checkLoginState = () => {
    window.FB.getLoginStatus(
      function (response) {
        this.statusChangeCallback(response);
      }.bind(this)
    );
  };

  facebookLogin = () => {
    window.FB.login(this.checkLoginState(), {
      scope: "email",
    });
  };

  statusChangeCallback(response) {
    if (response.status === "connected") {
      var formData = new FormData();
      formData.append("country", COUNTRYCODE);
      formData.append("partnerid", PARTNER_ID);
      formData.append("os", getOS);
      formData.append("uuid", UUID);
      formData.append("name", response.authResponse.userID);
      formData.append("type", "web");
      formData.append("authprovider", "facebook");
      formData.append("version", localStorage.getItem('version'));
      formData.append("authuid", response.authResponse.userID);
      formData.append("email", response.authResponse.userID);
      formData.append("newsletter", "0");
      axios
        .post(LOGIN, formData, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "6" || response.data.status === "5") {
              toast("Login Successfully", { transition: Zoom });
              localStorage.setItem("userid", response.data.userid);
              this.setState({ isLoading: true });
              setTimeout(() => (window.location.href = "/"), 1000);
            } else {
              toast("Something went wrong", { transition: Zoom });
            }
          }
        })
        .catch((error) => {
          toast("Something went wrong", { transition: Zoom });
        });
    } else if (response.status === "not_authorized") {
      console.log(
        "[FacebookLoginButton] Person is logged into Facebook but not your app"
      );
      toast("Something went wrong", { transition: Zoom });
    } else {
      console.log("[FacebookLoginButton] Person is not logged into Facebook");
      toast("Something went wrong", { transition: Zoom });
    }
  }

  handleChange(date) {
    // debugger
    console.log('date', date)
    // let dob = date
    this.setState({ startDate: date });
    // console.log('startDate', this.state.startDate)
  }


  getTokenLogin = (lemail, lpass) => {
    var formData1 = new FormData();
    formData1.append("partnerid", PARTNER_ID);
    axios
      .get(GET_TOKEN, formData1)
      .then((response) => {
        if (response.status === 200) {
          this.formLogin(lemail, lpass, response.data.Token);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  formSubmit = (e, lrole) => {
    e.preventDefault();
    if (lrole === "login") {
      var lemail = e.target.lemail.value.trim();
      var lpass = e.target.lpass.value.trim();
      var pass = lpass;
      this.getTokenLogin(lemail, pass);
    }
    if (lrole === "register") {
      //  debugger
      //"24-08-2022"
      let dobfin = this.state.startDate
      let days = dobfin.substring(0, 2);
      let month = dobfin.substring(3, 5);
      let year = dobfin.substring(6, 10);
      let DOB = days + '/' + month + '/' + year
      //let password_apttern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16}$)");
      let password_apttern = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9]).{6,128}$/);
      let msg = "";

      var semail = e.target.semail.value.trim();
      var spass = e.target.spass.value.trim();
      var scpass = e.target.scpass.value.trim();
      var sname = e.target.sname.value.trim();
      let country = e.target.country.value.trim();
      if (dobfin === "") {
        msg = "DOB field can not be blank";
        $("#snackbar .msg").html(msg);
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
        }, 3000);
        return false;
      }
      if (spass === "") {
        msg = "Password field can not be blank";
        $("#snackbar .msg").html(msg);
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
        }, 3000);
        return false;
      }

      if (scpass === "") {
        msg = "Confirm Password field can not be blank";
        $("#snackbar .msg").html(msg);
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
        }, 3000);
        return false;
      }

      if (!password_apttern.test(spass)) {
        $("#snackbar .msg").html(
          "Password must be at least 6 characters and contain both letters and numbers"
        );
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
        }, 10000);

        return false;
      }

      if (spass !== scpass) {
        msg = "Password and Confirm Password field did not match";
        $("#snackbar .msg").html(msg);
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
        }, 3000);
        return false;
      }

      if (country === "") {
        msg = "Please select the country";
        $("#snackbar .msg").html(msg);
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
        }, 3000);
        return false;
      }

      let getstorageToken = localStorage.getItem("token");
      this.formRegister(
        semail,
        spass,
        scpass,
        sname,
        DOB,
        country,
        getstorageToken
      );
    }
  };

  formLogin = (email, pass, ntoken) => {
    let formData = new FormData();
    formData.append("country", COUNTRYCODE);
    formData.append("partnerid", PARTNER_ID);
    formData.append("os", getOS);
    formData.append("uuid", UUID);
    formData.append("device_name", this.state.currentActiveDevice);
    formData.append("version", localStorage.getItem('version'));
    formData.append("type", "web");
    formData.append("authprovider", "web");
    formData.append("email", email);
    formData.append("password", pass);
    axios
      .post(LOGIN, formData, { headers: { token: ntoken } })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === 1 || response.data.status === "1") {
            // debugger
            // toast(response.data.msg, { transition: Zoom });
            $("#snackbar .msg").html(response.data.msg);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-success")
              .removeClass("bg-danger");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-success");
            }, 3000);
            localStorage.setItem("userid", response.data.userid);
            localStorage.setItem("user_name_val", email);
            localStorage.setItem("useremail", email);
            localStorage.setItem("token", response.data.Token);
            localStorage.setItem("isAuth", response.data.Token);
            localStorage.setItem("countrycode", response.data.country_code);
            this.setState({ isLoading: true });
            window.location.href = "/";
          } else if (response.data.status === "5") {
            // alert("multiple")
            this.setState({ loginDevices: response.data.Device_info, loginDevicesLength: response.data.Device_info.length, deviceLimit: response.data.login_limit, loginToken: response.data.Token, limitMessage: response.data.limit_msg });
          } else {
            $("#snackbar .msg").html(response.data.msg);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-danger")
              .removeClass("bg-success");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-danger");
            }, 3000);
          }
        }
      })
      .catch((error) => { });
  };

  formRegister = async (semail, spass, scpass, sname, DOB, country, ntoken) => {
    $("#signup_error").html("");
    $("#login_error").html("");
    if (semail === "" || spass === "" || sname === "" || DOB === "") {
      $("#signup_error").html("All fields must be filled");
      return false;
    }
    if (spass !== scpass) {
      $("#signup_error").html("Password doesn't matched");
      $("#snackbar .msg").html("Mobile Number Must Be Of 10 Digit");
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success ");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }
    let country_code = country ? country : "IN";
    localStorage.setItem("countrycode", country_code);

    let sPass = spass;
    let formData = new FormData();
    formData.append("partnerid", PARTNER_ID);
    formData.append("status", 2);
    formData.append("signup_token", localStorage.getItem("signup_token"));
    formData.append("version", localStorage.getItem("version"));
    formData.append("password", sPass);
    formData.append("device_name", this.state.currentActiveDevice);
    formData.append("dob", DOB);
    formData.append("os", getOS);
    formData.append("uuid", UUID);
    formData.append("country", country_code);
    formData.append("type", "web");
    axios
      .post(SIGNUPVERIFY, formData, { headers: { token: ntoken } })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "Success") {
            toast(response.data.data.message, { transition: Zoom });
            localStorage.setItem("userid", response.data.data.userid);
            localStorage.setItem("user_name_val", semail);
            localStorage.setItem("token", response.data.Token);
            localStorage.setItem("isAuth", response.data.Token);
            setTimeout(() => (window.location.href = "/"), 1000);
          } else {
            $("#snackbar .msg").html(response.data.data.message);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-danger")
              .removeClass("bg-success");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-danger");
            }, 3000);
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  success = (response) => {
    var formData = new FormData();
    formData.append("country", COUNTRYCODE);
    formData.append("partnerid", PARTNER_ID);
    formData.append("os", getOS);
    formData.append("uuid", UUID);
    formData.append("name", response.profileObj.name);
    formData.append("type", "web");
    formData.append("authprovider", "google");
    formData.append("version", localStorage.getItem('version'));
    formData.append("authuid", response.profileObj.googleId);
    formData.append("email", response.profileObj.email);
    formData.append("newsletter", "0");
    axios
      .post(LOGIN, formData, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "6" || response.data.status === "5") {
            toast("Login Successfully", { transition: Zoom });
            localStorage.setItem("userid", response.data.userid);
            this.setState({ isLoading: true });
            setTimeout(() => (window.location.href = "/"), 1000);
          } else {
            toast("Something went wrong", { transition: Zoom });
          }
        }
      })
      .catch((error) => {
        toast("Something went wrong", { transition: Zoom });
      });
  };
  error = (response) => {
    toast("Something went wrong with gmail login", { transition: Zoom });
  };
  togglePass = (id) => {
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  ResetPasswordSendOtp = async (e) => {
    e.preventDefault();
    // debugger
    let reset_email = this.inputEmail.current.value;
    if (reset_email !== '' && reset_email !== undefined && reset_email !== null) {
      // this.setState({ isProcessing: true });
      //let reset_email = e.target.uemail.value;
      //Accessing the ref using this.inputField.current
      //alert(reset_email)
      let formData = new FormData();
      formData.append("partnerid", PARTNER_ID);
      formData.append("email", reset_email);
      formData.append("provider", "application");

      await axios
        .post(FORGOT_PASSWORD, formData, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {


          if (res.data.status === 0) {
            //alert("stst 0")
            $("#snackbar .msg").html(res.data.Message);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-success")
              .removeClass("bg-danger");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-danger");
            }, 3000);

            this.forgetRef.current.className = this.forgetRef.current.className + " otpentr"
            //console.log("redffe",this.forgetRef.current.className+" otpentr")
            this.setState({
              forgetOTPSENDBUTTON: false,
              isforgetsendOTP: true,
              // otp_forgotcheck:false
            });


            if (this.state.FORGETOTP_time > 0) {
              forgettimer = setInterval(this.forgetotpTimer, 1000);
            }

            if (this.state.otpExpire === true) {
              document.getElementById("forgetPassEmailOTP1").value = "";
              document.getElementById("forgetPassEmailOTP2").value = "";
              document.getElementById("forgetPassEmailOTP3").value = "";
              document.getElementById("forgetPassEmailOTP4").value = "";
              this.setState({ FORGETOTP_time: 180 });
              forgettimer = setInterval(this.forgetotpTimer, 1000);
            }
          }
          if (res.data.status === 1) {
            // alert('ok sucess')
            // this.setState({ isProcessing: false });
            $("#snackbar .msg").html(res.data.Message);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-danger")
              .removeClass("bg-success");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-danger");
            }, 3000);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
      return false;
    } else {
      $("#snackbar .msg").html('Email field can not be blank');
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
    }
  };

  ResetPassword = async (e) => {
    //alert('kii')
    e.preventDefault();

    //let code = e.target.otp.value;
    let code = this.state.isFORGETOTP;
    let spass = e.target.newpassword.value;
    let scpass = e.target.confirmpassword.value;
    let email = e.target.uemail.value;
    let password_apttern = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9]).{6,128}$/);

    let msg = "";
    // debugger
    // console.log('e.key',e.keyCode)
    // if(e.key === 'enter'){
    //   alert('stauts true')
    // }
    if (code === "") {
      msg = "Please enter code";
      $("#snackbar .msg").html(msg);
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }

    if (spass === "") {
      msg = "Password field can not be blank";
      $("#snackbar .msg").html(msg);
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }

    if (email === "") {
      msg = "Email field can not be blank";
      $("#snackbar .msg").html(msg);
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }

    if (scpass === "") {
      msg = "Confirm Password field can not be blank";
      $("#snackbar .msg").html(msg);
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }

    if (!password_apttern.test(spass)) {
      $("#snackbar .msg").html(
        "Password must be at least 6 characters and contain both letters and numbers"
      );
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 10000);
      return false;
    }

    if (spass !== scpass) {
      msg = "Password and Confirm Password field did not match";
      $("#snackbar .msg").html(msg);
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }
    let resetFormPassword = new FormData();
    resetFormPassword.append("email", email);
    resetFormPassword.append("code", code);
    resetFormPassword.append("password", spass);
    resetFormPassword.append("tag", "2");
    resetFormPassword.append("reset_token", localStorage.getItem("forgetPassToken"));
    resetFormPassword.append("version", localStorage.getItem("version"));

    await axios
      .post(FORGETVERIFY, resetFormPassword, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((resetPassword) => {
        // debugger       
        // console.log('tag2',resetPassword)
        if (resetPassword.data.data.status === "Success") {
          // debugger
          // alert(' status tag 2 sucess')
          let message = resetPassword.data.data.message;
          $("#snackbar .msg").html(message);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-success")
            .removeClass("bg-danger");
          setTimeout(function () {
            $("#snackbar").removeClass("show").removeClass("bg-danger");
            window.location.href = "/login";
          }, 3000);
          return false;
        }

        else {
          // alert(' status tag 2 faliuer')
          let msg = resetPassword.data.mgs;
          $("#snackbar .msg").html(msg);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-danger")
            .removeClass("bg-success");
          setTimeout(function () {
            $("#snackbar").removeClass("show");
            // localStorage.clear();
            // window.location.href = "/login";
          }, 3000);
        }
      })
      .catch((error) => { });
    return false;
  };

  openLogin = () => {
    window.gtag("event", "page_view", {
      "Page title": "LOGIN",
    });
    // this.setState({ isLogin: true, isSignup: false, OTP_time: 180, });
    window.location.assign('/login')
  };

  openRegister = () => {
    window.gtag("event", "page_view", {
      "Page title": "SIGN UP",
    });
    this.setState({ isLogin: false, isSignup: true });
  };

  openForgotPasswordForm = () => {
    window.gtag("event", "page_view", {
      "Page title": "FORGOT PASSWORD",
    });

    this.setState({ isLogin: false, isSignup: false, isForget: true });
  };

  otpTimer = () => {
    this.setState({ OTP_time: this.state.OTP_time - 1 });
    if (this.state.OTP_time === 0) {
      clearInterval(timer);
      this.setState({ OTP_time: 0, otpExpire: true });
    }
  };

  forgetotpTimer = () => {
    this.setState({ FORGETOTP_time: this.state.FORGETOTP_time - 1 });
    if (this.state.FORGETOTP_time === 0) {
      clearInterval(forgettimer);
      this.setState({ FORGETOTP_time: 0, otpExpire: true });
    }
  };

  sendForOTP = async (e) => {
    let formData1 = new FormData();
    formData1.append("partnerid", PARTNER_ID);
    await axios
      .get(GET_TOKEN, formData1)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.Token);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let emailInput = document.getElementById("semail");
    let username = document.getElementById("sname").value;
    if (username.length < 2 || username.length > 20) {
      let msg =
        "Please enter name minimum 2 characters or maximum 20 characters";
      $("#snackbar .msg").html(msg);
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }
    if (emailInput.value.match(mailformat) && username) {
      emailInput.classList.remove("invalid");
      this.setState({ isProcessing: true });
      let otpSend = new FormData();
      otpSend.append("partnerid", PARTNER_ID);
      otpSend.append("email", emailInput.value);
      otpSend.append("username", username);
      let getlstoken = { token: localStorage.getItem("token").trim() };
      await axios
        .post(SIGNUP, otpSend, { headers: { token: getlstoken.token } })
        .then((res) => {
          let ins = this
          if (res.data.status === "Success") {
            ins.setState({
              sendForOTP: true,
              emailInputState: false,
              isProcessing: false,
            });
            document.getElementById("semail").readOnly = true;
            document.getElementById("emailsubmitbtn").style.display = "none";
            document.getElementById("edit_email").style.display = "black";
            document.getElementById("edit_email").style.display = "revert";
            $("#snackbar .msg").html(res.data.data.message);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-success")
              .removeClass("bg-danger");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-danger");
            }, 3000);

            if (this.state.OTP_time > 0) {
              timer = setInterval(this.otpTimer, 1000);
            }

            if (this.state.otpExpire === true) {
              document.getElementById("emailOTP1").value = "";
              document.getElementById("emailOTP2").value = "";
              document.getElementById("emailOTP3").value = "";
              document.getElementById("emailOTP4").value = "";
              this.setState({ OTP_time: 180 });
              timer = setInterval(this.otpTimer, 1000);
            }
          } else {
            this.setState({ isProcessing: false });
            $("#snackbar .msg").html(res.data.data.message);
            $("#snackbar")
              .addClass("show")
              .addClass("bg-danger")
              .removeClass("bg-success");
            setTimeout(function () {
              $("#snackbar").removeClass("show").removeClass("bg-danger");
            }, 3000);
            return false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
      return true;
    } else {
      $("#snackbar .msg").html("Invalid email address!");
      $("#snackbar")
        .addClass("show")
        .addClass("bg-danger")
        .removeClass("bg-success");
      setTimeout(function () {
        $("#snackbar").removeClass("show").removeClass("bg-danger");
      }, 3000);
      return false;
    }
  };

  checkOTP = async (e) => {
    if (e.target.value !== "") {
      const state = this.state;
      const name = e.target.name;
      state[name] = e.target.value;
      this.setState({
        otp_ele_length:
          this.state.otp_ele_length >= 4 ? 4 : this.state.otp_ele_length + 1,
        isOTP:
          this.state.emailOTP1 +
          this.state.emailOTP2 +
          this.state.emailOTP3 +
          this.state.emailOTP4,
      });

      let input = e.target.getAttribute("id");
      if (input !== "emailOTP4") {
        e.target.nextSibling.focus();
      }
    }

    if (e.target.value === "") {
      this.setState({
        otp_ele_length:
          this.state.otp_ele_length === 1 ? 0 : this.state.otp_ele_length - 1,
      });
    }
  };

  isOTPEmpty = (e) => {
    // console.log("");
  };


  checkForgetOTP = async (e) => {
    if (e.target.value !== "") {
      const state = this.state;
      const name = e.target.name;
      state[name] = e.target.value;
      this.setState({
        forgetotp_ele_length:
          this.state.forgetotp_ele_length >= 4 ? 4 : this.state.forgetotp_ele_length + 1,
        isFORGETOTP:
          this.state.forgetPassEmailOTP1 +
          this.state.forgetPassEmailOTP2 +
          this.state.forgetPassEmailOTP3 +
          this.state.forgetPassEmailOTP4,
      });

      let input = e.target.getAttribute("id");
      if (input !== "forgetPassEmailOTP4") {
        e.target.nextSibling.focus();
      }
    }

    if (e.target.value === "") {
      this.setState({
        forgetotp_ele_length:
          this.state.forgetotp_ele_length === 1 ? 0 : this.state.forgetotp_ele_length - 1,
      });
    }
  };

  isForgetOTPEmpty = (e) => {
    //console.log("");
  };

  emailInput = (e) => {
    this.setState({ emailInputState: e.target.value });
  };

  editEmail = (e) => {
    document.getElementById("semail").readOnly = false;
    this.setState({ emailInputState: true });
    this.setState({ sendForOTP: false });
    document.getElementById("emailsubmitbtn").style.display = "revert";
    document.getElementById("edit_email").style.display = "none";
    clearInterval(timer);
    this.setState({ OTP_time: 180 });
  };

  checkOTPNo = async () => {
    let emailInput = document.getElementById("semail").value;
    let otpverify = new FormData();
    otpverify.append("partnerid", PARTNER_ID);
    otpverify.append("email", emailInput);
    otpverify.append("otp", this.state.isOTP);
    otpverify.append("version", localStorage.getItem("version"));
    otpverify.append("status", 1);

    await axios
      .post(SIGNUPVERIFY, otpverify, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "Success") {
          localStorage.setItem("signup_token", res.data.data.signup_token);

          $("#snackbar .msg").html(res.data.data.message);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-success")
            .removeClass("bg-danger");
          setTimeout(function () {
            $("#snackbar").removeClass("show").removeClass("bg-danger");
          }, 3000);
          this.setState({
            sendForOTP: false,
            otp_check: true,
            emailverify: true,
          });
          document.getElementById("edit_email").style.display = "none";
          document.getElementById("submit_button").style.display = "revert";
        } else {
          $("#snackbar .msg").html(res.data.data.message);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-danger")
            .removeClass("bg-success");
          setTimeout(function () {
            $("#snackbar").removeClass("show").removeClass("bg-danger");
          }, 3000);
          return false;
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  backtosigup() {
    // this.setState({
    //   isLogin: true,
    //   isForget: false,
    //   forgetOTPSENDBUTTON: true,
    //   isforgetsendOTP: false,
    //   FORGETOTP_time: 180,
    // })
    window.location.assign('/login')
  }

  checkFORGETOTPNo = async () => {
    //let emailInput = document.getElementById("semail").value;
    let emailInput = this.inputEmail.current.value;
    let otpverify = new FormData();
    otpverify.append("email", emailInput);
    otpverify.append("code", this.state.isFORGETOTP);
    otpverify.append("tag", '1');

    await axios
      .post(FORGETVERIFY, otpverify, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data.status === "Success") {
          // alert('tag 1 sucess')
          // debugger
          localStorage.setItem("forgetPassToken", res.data.data.reset_token);
          this.setState({
            otp_forgotcheck: true
          })
          if (this.state.otp_forgotcheck === true) {
            this.newpassword.current.focus()
          }
          $("#snackbar .msg").html(res.data.data.message);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-success")
            .removeClass("bg-danger");
          setTimeout(function () {
            $("#snackbar").removeClass("show").removeClass("bg-danger");
          }, 3000);
          this.setState({
            isforgetsendOTP: false
          });
        } else {
          $("#snackbar .msg").html(res.data.data.message);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-danger")
            .removeClass("bg-success");
          setTimeout(function () {
            $("#snackbar").removeClass("show").removeClass("bg-danger");
          }, 3000);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  close_download_app_strip = () => {
    document.getElementById("download_app_section_header").style.display =
      "none";
  };

  isOpenLogin = () => {
    this.setState({ isOpenModal: true });
  };

  isKnowMoreModal = () => {
    this.setState({ isKnowMoreModal: true });
  };

  hideModal = () => {
    this.setState({ isOpenModal: false });
    this.setState({ isKnowMoreModal: false });
  };

  setRef = (ref) => {
    this.multiDeviceRef.push(ref);
  };

  MultiDevicelogOut = (UUID, key) => {
    // alert("UUID"+UUID)
    let formData = new FormData();
    formData.append("uuid", UUID);
    axios
      .post(MULTIDEVICELOGOUT, formData, {
        headers: { 'token': this.state.loginToken },
      })
      .then((response) => {
        if (response.data.status === "success") {
          this.multiDeviceRef[key].classList.remove("d-flex");
          this.multiDeviceRef[key].classList.add("d-none");
          $("#snackbar .msg").html(response.data.msg);
          $("#snackbar")
            .addClass("show")
            .addClass("bg-success")
            .removeClass("bg-danger");
          setTimeout(function () {
            $("#snackbar").removeClass("show").removeClass("bg-danger");
          }, 3000);
          this.setState({ loginDevicesLength: this.state.loginDevicesLength - 1 });
          if (this.state.loginDevicesLength < parseInt(this.state.deviceLimit)) this.setState({ loginDevices: [] });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  deviceLogout = (UUID, key) => {
    this.MultiDevicelogOut(UUID, key);
    // console.log("multiDeviceRef", this.multiDeviceRef[key].id);
  };

  closeDevicePopup = () => {
    this.setState({ loginDevices: [] });
  }

  render() {
    const { forgetOTPSENDBUTTON, isforgetsendOTP, loginDevices, currentActiveDevice, limitMessage } = this.state
    const territory_list = COUNTRYLIST.map((result, key) => {
      return <option value={result.code}>{result.name}</option>;
    });
    if (!this.state.isLoading) {
      // console.log('startDate', this.state.startDate)
      return (
        <div className="root">
          {this.state.window_width < 767 && (
            <div id="download_app_section_header">
              <div className="download_app_section_header d-flex align-items-center">
                <a className="" onClick={this.close_download_app_strip}>
                  <CloseIcon />
                </a>
                <div className="d-flex align-items-center ml-2">
                  <span className="icon">
                    <img src={app_icon} alt="" />
                  </span>
                  <span className="ml-2">DOWNLOAD THE APP</span>
                </div>

                {this.state.detect_device === "Android" && (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.AmritaLiveTV.activity"
                    className="btn ml-auto"
                    target="_blank"
                  >
                    Install Now
                  </a>
                )}
                {this.state.detect_device === "IOS" && (
                  <a
                    href="https://apps.apple.com/us/app/amrita-live/id1559510636"
                    className="btn ml-auto"
                    target="_blank"
                  >
                    Install Now
                  </a>
                )}
              </div>
            </div>
          )}

          {this.state.isOpenModal && <div className="custom_modal transition open">
            <div className="body card transition">
              <div className="">
                <span
                  className="close d-flex align-items-center justify-content-center"
                  onClick={this.hideModal}
                >
                  &#x2715;
                </span>
                <div className="scroll">
                  <h4 className="text-center font-weight-bold mb-3">
                    CONTACT US | AMRITA LIVE
                  </h4>
                  <p className="text-center font-18">
                    If you have any issues or suggestions regarding the use of
                    Amrita Live,
                    <br />
                    feel free to contact us:
                  </p>

                  <p className="text text-center">
                    <strong>Email:</strong>{" "}
                    <a href="mailto:support@amritalive.com">
                      support@amritalive.com
                    </a>
                  </p>
                  <p className="text text-center">
                    <strong>Chat Support:</strong> (+91) 9288033353, (+91)
                    9288033352, (+91) 9288033351{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>}

          {this.state.isKnowMoreModal && <div className="custom_modal know_more_modal transition open">
            <div className="body card transition">
              <div className="">
                <span
                  className="close d-flex align-items-center justify-content-center"
                  onClick={this.hideModal}
                >
                  &#x2715;
                </span>
                <div className="scroll">
                  <h4 className="font-weight-bold mb-3">ABOUT US</h4>
                  <p className="font-14">
                    Welcome to the serene and uplifting world of Amma's love and
                    wisdom!!!
                  </p>
                  <p className="font-14">
                    'Amrita Live' is an exclusive Over-the-Top (OTT) platform
                    dedicated to content related to Amma, Satguru Sri Mata
                    Amritanandamayi Devi, the world-renowned Indian spiritual &
                    humanitarian leader whose life and message of love &
                    compassion inspires and transforms millions around the
                    globe. The Mata Amritanandamayi Math (MAM), an international
                    charitable organization aimed at humankind's spiritual and
                    material upliftment, was founded by Amma in 1981 with its
                    headquarters in Amritapuri, Kerala, India. 'Amrita Live'
                    originates from this global entity with a mission to cater
                    to the spiritual needs of tens and millions of Amma's
                    devotees and like-minded viewers all over the world.
                  </p>

                  <h5 className="font-16 font-w-600">Exclusive Features  </h5>
                  <p className="font-14">
                    Amrita Live showcases videos of Amma’s worldwide spiritual
                    tours, satsang, bhajans, rare archive footage, and much
                    more, which will be a wonderful treat for Amma’s devotees
                    worldwide. It will be the best reliable source for the same.
                  </p>

                  <p>
                    <strong>Amma Live Tab:</strong> One of the highlights of
                    this platform is the LIVE streaming of Amma’s special day
                    programs from Amritapuri or other prominent locations of Her
                    visit.
                  </p>

                  <p>
                    <strong>Multilingual Series:</strong> Another exclusive
                    feature is the ‘Multilingual Series’ of Amma’s popular TV
                    shows- ‘Ammayodoppam’ and ‘Amrit Ganga.’ The two shows are
                    available in English, Hindi, Tamil, and Malayalam languages.
                    All the older episodes of Amritavarsham, Ammayodoppam,
                    Amma’s bhajans, Amma’s senior disciples’ satsangs etc. will
                    be uploaded in a phased manner.
                  </p>

                  <p>
                    <strong>TV Live Tab:</strong> You can watch Amrita
                    Television LIVE on this platform 24X7. It also showcases the
                    channel’s wonderful array of quality content ranging from
                    spirituality to family entertainment and news & current
                    affairs to super hit movies with a seven-day Catch-Up TV.
                    Many of the videos not available on YouTube or other online
                    platforms are available on Amrita Live, especially Amrita
                    TV’s old shows!
                  </p>

                  <p>Below are the links to download the App:  </p>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.AmritaLiveTV.activity"
                    target="_blank"
                    className="text-underling"
                    style={{ textDecoration: "underling" }}
                  >
                    Google Play store
                  </a>
                  <br />
                  <a
                    href="https://apps.apple.com/us/app/amrita-live/id1559510636"
                    target="_blank"
                    className="text-underling"
                    style={{ textDecoration: "underling" }}
                  >
                    Apple store
                  </a>
                  <br />

                  <a
                    href="https://amritatvonline.com/"
                    target="_blank"
                    className="text-underling"
                    style={{ textDecoration: "underling" }}
                  >
                    Watch on Web App
                  </a>

                  <br />
                  <br />

                  <p>
                    You can also watch the ‘User Guide’ videos on our YouTube
                    channel-
                    <a
                      href="https://www.youtube.com/c/amritalive/videos"
                      target="_blank"
                    >
                      AmritaLive
                    </a>
                  </p>

                  <p>
                    Follow us on:{" "}
                    <a
                      href="https://www.facebook.com/amritaliveott"
                      target="_blank"
                    >
                      Facebook{" "}
                    </a>{" "}
                    |{" "}
                    <a href="https://twitter.com/LiveAmrita" target="_blank">
                      Twitter{" "}
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://www.instagram.com/amritalive/channel/"
                      target="_blank"
                    >
                      Instagram
                    </a>{" "}
                  </p>

                  <p>
                    <strong>Contact us:</strong>
                  </p>
                  <p className="mb-0">
                    <strong>Email:</strong>{" "}
                    <a href="mailto:support@amritalive.com">
                      support@amritalive.com
                    </a>
                  </p>
                  <p>
                    <strong>Chat Support (WhatsApp and Telegram): </strong> +91
                    9288033353, +91 9288033352, +91 9288033351
                  </p>

                  <p>
                    Sign Up today and enjoy your favourite shows anywhere,
                    anytime, and be a part of this spiritual voyage with the
                    universal mother, Amma, to explore the vast ocean of love
                    and compassion!
                  </p>
                </div>
              </div>
            </div>
          </div>}

          <div className="login_page_wrapper row align-items-center vh-100 ">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 h-100">
                  <div className="rspnsv-img">
                    <img src={rspnsvImage} alt="" />
                  </div>
                  <div className="lgn_title_Hdng h-100">
                    <div className="logo text-right">
                      <a href="/">
                        <img className="login_img" src={localStorage.getItem("logoUrl")} alt="" />
                      </a>
                    </div>
                    <div class="title text-center">
                      <h1>
                        {" "}
                        Welcome to <em>Amrita Live</em>{" "}
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 res-hide h-100">
                  <div className="lgn_title_Hdng h-100">
                    <div className="logo text-right">
                      <a href="/">
                        <img className="login_img" src={Logoimg} alt="" />
                      </a>
                    </div>
                    <div class="title text-center">
                      <h1>
                        {" "}
                        Welcome to <em>Amrita Live</em>{" "}
                      </h1>
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex h-100">
                  <div className="Logn_wppr ">
                    <div className="login_signup ">
                      {this.state.isLogin && (
                        <div>
                          <div className="heading_modal w-50 ml-auto">
                            <h2>Login to continue</h2>
                          </div>
                          <form onSubmit={(e) => this.formSubmit(e, "login")}>

                            <div className="modal_form_content ">
                              <div className="inputbox input-container">
                                <img
                                  src={MailIcn}
                                  alt="icon"
                                  className="icon"
                                />
                                <input
                                  type="email"
                                  className="input-field"
                                  placeholder="Email address"
                                  alt=""
                                  name="lemail"
                                  required
                                />
                              </div>
                              <div className="inputbox input-container">

                                <img
                                  src={PswrdIcn}
                                  alt="icon"
                                  className="icon"
                                />
                                <input
                                  id="password-field"
                                  className="input-field"
                                  type="password"
                                  placeholder="Password"
                                  name="lpass"
                                  alt=""
                                  required
                                />
                              </div>
                              <div className="forgot_psw_link ">
                                <a
                                  href="#"
                                  onClick={this.openForgotPasswordForm}
                                >
                                  Forgot Password?
                                </a>
                              </div>
                              <span
                                className="valid_error"
                                id="login_error"
                              ></span>
                              <div className="buttons">
                                <button
                                  type="submit"
                                  className="orange-login-btn"
                                >
                                  Login
                                </button>
                              </div>
                              <div className="mor_btn">
                                <a
                                  className=" cursor"
                                  onClick={this.isKnowMoreModal}
                                >
                                  Know More
                                </a>
                              </div>
                              <div className="Hlp_Btn">
                                <a
                                  className=" cursor"
                                  onClick={this.isOpenLogin}
                                >
                                  Need Help ?
                                </a>
                              </div>
                              <div className="Rmbr-wppr">
                                <div className="Rmbr-txt-Frm-Wppr">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    className="chckbx_btn"
                                    alt=""
                                  />
                                  <label></label>
                                  <p>Remember me</p>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div>
                            <div className="social_login_block"></div>
                            <div>
                              <button className="signup_btn">
                                Don't have an account{" "}
                                <span onClick={this.openRegister}>Sign Up</span>
                              </button>
                            </div>
                          </div>
                          {/* connectedDevices Popover start */}
                          {loginDevices.length > 0 && <div className="connectedDevicesPopover p-0">
                            <div className="shadow">
                              <Button onClick={this.closeDevicePopup} className="close_"><CloseIcon /></Button>
                              <div className="head">
                                <h4>{currentActiveDevice}({getOS})</h4>
                                <p>Your device limit has been exceeded. To continue logout of any one of the devices below.</p>
                              </div>
                              <div className="head pt-3 pb-3">
                                <h3>Manage Devices</h3>
                                <p>Connected Devices</p>
                              </div>
                              <div className="scroll">
                                {loginDevices.map((value, key) => (
                                  <div className="data d-flex align-items-center w-100" ref={this.setRef} id={`device_${value.device_uuid}`}>
                                    <div className="w-75">
                                      <h3>{value.device_name}({value.os})</h3>
                                      <p> <Moment format="D/MMMM/YYYY">{(JSON.stringify(value.last_login)).replace(/"/g, "")}</Moment> </p>
                                    </div>

                                    <div className="w-25">
                                      <Button id={key} onClick={() => this.deviceLogout(value.device_uuid, key)}>Logout</Button>
                                    </div>
                                  </div>))}
                              </div>
                            </div>
                            <h4 className="text-y font-24 my-2">{`*${limitMessage}`}</h4>
                          </div>}

                          {/* <h4 className="text-y font-24 my-2">* The application support 3 devices at a time.</h4> */}
                          {/* connectedDevices Popover end */}
                        </div>
                      )}
                      {this.state.isSignup && (
                        <>
                          <form
                            onSubmit={(e) => this.formSubmit(e, "register")}
                            className="modal_form_content"
                          >
                            <div className="SgnUp_hdng_Wppr Sgnup_hdng d-flex align-items-center justify-content-between w-100 mb-2">
                              <div
                                className="bck_btn SgnUpBck"
                                onClick={this.openLogin}
                              >
                                <img src={bckIcn} alt={bckIcn} />
                                {/* <i
                                  class="fa fa-long-arrow-left"
                                  aria-hidden="true"
                                ></i> */}
                              </div>
                              <h2>Sign Up</h2>
                            </div>
                            <div className="form-group inputbox input-container">
                              <img src={UserIcn} alt="icon" className="icon" />
                              <input
                                type="text"
                                className="form-control input-field"
                                placeholder="Name"
                                alt=""
                                id="sname"
                                name="sname"
                                required
                              />
                            </div>
                            <div className="form-group position-relative inputbox input-container">
                              <img src={MailIcn} alt="icon" className="icon" />
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                type="email"
                                id="semail"
                                className="form-control input-field"
                                placeholder="Your email"
                                alt=""
                                name="semail"
                                required
                                onChange={this.emailInput}
                              />
                              <a
                                id="edit_email"
                                className="edit_email ml-auto border-effect"
                                onClick={this.editEmail}
                              >
                                Edit mail
                              </a>
                            </div>
                            <div className="buttons entr-btn">
                              <button
                                disabled={this.state.isProcessing}
                                id="emailsubmitbtn"
                                onClick={this.sendForOTP}
                                type="button"
                                className="orange-login-btn m-0"
                              >
                                {this.state.isProcessing
                                  ? "OTP Sending..."
                                  : `Enter`}
                              </button>

                              {this.state.emailverify && (
                                <span className="email_verify">
                                  <VerifiedUserIcon />
                                </span>
                              )}
                            </div>
                            {this.state.sendForOTP === true && (
                              <div className="signUp_otp">
                                <p className="mb-1">
                                  Please Enter your OTP code sent to your Email
                                  ID
                                </p>

                                <div className="d-flex align-items-center mb-2 otp_box position-relative">
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="emailOTP1"
                                    name="emailOTP1"
                                    onChange={this.checkOTP}
                                    onBlur={this.isOTPEmpty}
                                    maxLength="1"
                                  />
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="emailOTP2"
                                    name="emailOTP2"
                                    onChange={this.checkOTP}
                                    onBlur={this.isOTPEmpty}
                                    maxLength="1"
                                  />
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="emailOTP3"
                                    name="emailOTP3"
                                    onChange={this.checkOTP}
                                    onBlur={this.isOTPEmpty}
                                    maxLength="1"
                                  />
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="emailOTP4"
                                    name="emailOTP4"
                                    onChange={this.checkOTP}
                                    onBlur={this.isOTPEmpty}
                                    maxLength="1"
                                  />
                                </div>
                                <div className="mt-2 mb-2 d-flex align-items-center otp_dur mb-3">
                                  <span className="otp_time">
                                    Waiting for OTP: {this.state.OTP_time} sec
                                  </span>
                                  {this.state.OTP_time === 0 && (
                                    <span
                                      className="resendOTP ml-auto"
                                      onClick={this.sendForOTP}
                                    >
                                      Resend OTP
                                    </span>
                                  )}
                                </div>
                                {this.state.otp_check === true && (
                                  <p
                                    style={{
                                      color: "#ae56c9",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Your OTP Successfully verified
                                  </p>
                                )}

                                {this.state.otp_check === false && (
                                  <p
                                    style={{
                                      color: "#ae56c9",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    OTP Not match
                                  </p>
                                )}
                              </div>
                            )}
                            <div className="form-group inputbox input-container">
                              <img src={PswrdIcn} alt="icon" className="icon" />
                              <input
                                id="rpassword"
                                className="form-control input-field"
                                type="password"
                                placeholder="Password"
                                alt=""
                                name="spass"
                                disabled={!this.state.otp_check}
                                required
                              />
                            </div>

                            <div className="msgpoptxt-wppr">
                              <span className="txthdng">
                                Password must be at least 6 characters and
                                contain both letters and numbers
                              </span>
                            </div>

                            <div className="form-group inputbox input-container">
                              <img src={PswrdIcn} alt="icon" className="icon" />
                              <input
                                type="password"
                                className="form-control input-field"
                                placeholder="Confirm Password"
                                id="pwd"
                                name="scpass"
                                disabled={!this.state.otp_check}
                                required
                              />
                            </div>

                            {/* <div className="form-group inputbox input-container dateFieldWrapper">
                              <img src={DateIcn} alt="icon" className="icon" />                             
                              <input type="date"  className="form-control wd-100 input-field dateField" onChange={(e)=>this.handleChange(e) } />
                              <label>Date of Birth <span style={{fontWeight:'600'}}>{this.state.startDate==="" ? '(DD/MM/YYYY)' : `(${this.state.startDate})`}</span></label>
                            </div>
                             */}
                            <div className="form-group inputbox input-container" style={{ pointerEvents: this.state.otp_check === 0 ? 'none' : 'inherit', cursor: 'not-allowed' }}>
                              <img src={DateIcn} alt="icon" className="icon" />
                              {/* <DatePicker
                                selected={this.state.startDate}
                                onChange={(value, e) =>
                                  this.handleChange(value, e)
                                }
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Date of Birth (DD/MM/YYYY)"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                maxDate={new Date()}
                                className="form-control wd-100 input-field"
                                disabled={!this.state.otp_check}
                              /> */}
                              <ModernDatepicker
                                date={this.state.startDate}
                                // minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 13))}
                                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 13))}
                                format={'DD-MM-YYYY'}
                                className="form-control wd-100 input-field"
                                onChange={date => this.handleChange(date)}
                                placeholder="Date of Birth (DD/MM/YYYY)"
                              />
                            </div>

                            <div class="form-group  inputbox input-container slct-bx">
                              <img src={CntryIcn} alt="icon" className="icon" />
                              <select disabled={!this.state.otp_check}
                                name="country"
                                className="form-control wd-100 input-field pl-2"
                              >
                                <option value=""> Select Country </option>
                                {territory_list}
                              </select>
                            </div>

                            <div className="buttons">
                              <span
                                style={{ color: "red", textAlign: "center" }}
                                className="text-center"
                                id="signup_error"
                              ></span>
                              <button disabled={!this.state.otp_check}
                                id="submit_button"
                                type="submit"
                                className="orange-login-btn"
                              >
                                Register
                              </button>
                            </div>
                          </form>
                        </>
                      )}
                      {this.state.isForget && (
                        <>
                          <form
                            onSubmit={(e) => this.ResetPassword(e)}
                            className="modal_form_content"
                          >
                            <div className="SgnUp_hdng_Wppr d-flex align-items-center justify-content-between w-100 ">
                              <div
                                className="bck_btn SgnUpBck"
                                onClick={() =>
                                  this.backtosigup()
                                }
                              >
                                <img src={bckIcn} alt={bckIcn} />
                                {/* <i
                                  class="fa fa-long-arrow-left"
                                  aria-hidden="true"
                                ></i> */}
                              </div>
                              <div className="heading_modal">
                                <h2>Forgot password</h2>
                              </div>
                            </div>
                            <p className="text-right w-100 ml-auto mb-4 pl-5 ">
                              Don't worry! Please enter your registered email to
                              reset your password
                            </p>
                            <div className="form-group inputbox input-container my-3">
                              <img src={MailIcn} alt="icon" className="icon" />
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                type="email"
                                className="form-control input-field"
                                placeholder="Email address"
                                name="uemail"
                                ref={this.inputEmail}
                                required
                              />
                            </div>
                            <div className="buttons">
                              <span
                                style={{ color: "red", textAlign: "center" }}
                                className="text-center"
                                id="signup_error"
                              ></span>
                              {forgetOTPSENDBUTTON && (<button
                                disabled={this.state.isProcessing}
                                onClick={this.ResetPasswordSendOtp}
                                type="button"
                                className="orange-login-btn mt-0"
                              >
                                {this.state.isProcessing
                                  ? "Processing..."
                                  : `Send OTP`}
                              </button>)
                              }
                            </div>
                            <div className="otp-wppr mt-3" ref={this.forgetRef}>
                              {/* <div className="mt-3"> */}
                              <p>
                                Please enter your otp to reset your password
                              </p>

                              <div className="form-group flex-wrap inputbox input-container my-2">
                                <div className="d-flex align-items-center mb-2 otp_box position-relative input-field px-2">
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="forgetPassEmailOTP1"
                                    name="forgetPassEmailOTP1"
                                    onChange={this.checkForgetOTP}
                                    onBlur={this.isForgetOTPEmpty}
                                    maxLength="1"
                                  />
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="forgetPassEmailOTP2"
                                    name="forgetPassEmailOTP2"
                                    onChange={this.checkForgetOTP}
                                    onBlur={this.isForgetOTPEmpty}
                                    maxLength="1"
                                  />
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="forgetPassEmailOTP3"
                                    name="forgetPassEmailOTP3"
                                    onChange={this.checkForgetOTP}
                                    onBlur={this.isForgetOTPEmpty}
                                    maxLength="1"
                                  />
                                  <input
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    type="text"
                                    className="mr-2 form-control"
                                    id="forgetPassEmailOTP4"
                                    name="forgetPassEmailOTP4"
                                    onChange={this.checkForgetOTP}
                                    onBlur={this.isForgetOTPEmpty}
                                    maxLength="1"
                                  />
                                </div>
                                {isforgetsendOTP && <div className="w-100 d-flex">
                                  <span className="otp_time">
                                    Waiting for OTP: {this.state.FORGETOTP_time} sec
                                  </span>
                                  {this.state.FORGETOTP_time === 0 && (
                                    <span
                                      className="resendOTP ml-auto"
                                      onClick={this.ResetPasswordSendOtp}
                                    >
                                      Resend OTP
                                    </span>
                                  )}
                                </div>
                                }
                                {this.state.forgetotp_check === true && (
                                  <p
                                    style={{
                                      color: "#ae56c9",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Your OTP Successfully verified
                                  </p>
                                )}

                                {this.state.forgetotp_check === false && (
                                  <p
                                    style={{
                                      color: "#ae56c9",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    OTP Not match
                                  </p>
                                )}
                              </div>
                              <div className="form-group  inputbox input-container">
                                <img
                                  src={PswrdIcn}
                                  alt="icon"
                                  className="icon"
                                />
                                <input
                                  type="password"
                                  className="form-control input-field"
                                  placeholder="New Password"
                                  name="newpassword"
                                  disabled={!this.state.otp_forgotcheck}
                                  ref={this.newpassword}
                                />
                              </div>
                              <div className="msgpoptxt-wppr my-2">
                                <span className="txthdng">
                                  Password must be at least 6 characters and
                                  contain both letters and numbers
                                </span>
                              </div>
                              <div className="form-group inputbox input-container">
                                <img
                                  src={PswrdIcn}
                                  alt="icon"
                                  className="icon"
                                />
                                <input
                                  type="password"
                                  className="form-control  input-field"
                                  placeholder="Confirm Password"
                                  name="confirmpassword"
                                  disabled={!this.state.otp_forgotcheck}
                                />
                              </div>
                              <div className="buttons rst_btn">
                                <span
                                  style={{ color: "red", textAlign: "center" }}
                                  className="text-center"
                                ></span>
                                <button
                                  type="submit"
                                  className="orange-login-btn"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}

                      {/* {this.state.isResetPassword && (
                        <>
                          <form
                            onSubmit={(e) => this.ResetPassword(e)}
                            className="modal_form_content"
                          >
                            <div className="SgnUp_hdng_Wppr d-flex align-items-center justify-content-between w-100 ">
                              <div
                                className="bck_btn SgnUpBck"
                                onClick={() =>
                                  this.setState({
                                    isLogin: false,
                                    isForget: true,
                                    isResetPassword: false,
                                  })
                                }
                              >
                                <i
                                  class="fa fa-long-arrow-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <h2>Reset password</h2>
                              </div>
                            </div>
                            <p>Please enter your otp to reset your password</p>
                            <div className="form-group inputbox input-container my-2">
                              <img src={PswrdIcn} alt="icon" className="icon" />
                              <input
                                type="text"
                                className="form-control input-field"
                                placeholder="OTP"
                                alt=""
                                name="otp"
                                required
                              />
                            </div>
                            <div className="form-group  inputbox input-container">
                              <img src={PswrdIcn} alt="icon" className="icon" />
                              <input
                                type="password"
                                className="form-control input-field"
                                placeholder="New Password"
                                alt=""
                                name="newpassword"
                                required
                              />
                            </div>
                            <div className="msgpoptxt-wppr my-2">
                              <span className="txthdng">
                                Password must be at least 6 characters and
                                contain both letters and numbers
                              </span>
                            </div>
                            <div className="form-group inputbox input-container">
                              <img src={PswrdIcn} alt="icon" className="icon" />
                              <input
                                type="password"
                                className="form-control  input-field"
                                placeholder="Confirm Password"
                                alt=""
                                name="confirmpassword"
                                required
                              />
                            </div>
                            <div className="buttons rst_btn">
                              <span
                                style={{ color: "red", textAlign: "center" }}
                                className="text-center"
                              ></span>
                              <button
                                type="submit"
                                className="orange-login-btn"
                              >
                                Reset
                              </button>
                            </div>
                          </form>
                        </>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        // <div className="root">
        //   <div className="login_f">
        //     <div className="login_signup text-center">
        //       <div className="popup_l">
        //         <img src="images/logo.png" alt="" />
        //       </div>
        //       <Loader type="Rings" color="#9012cd" height={100} width={100} />
        //       <p>Signing In ,Please Wait</p>
        //     </div>
        //   </div>
        // </div>
        <Loader />
      );
    }
  }
}
export default Login;
