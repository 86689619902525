import React from 'react';
import Loadable from 'react-loadable';
function Loading() {
  return <div></div>;
}
const Login = Loadable({
  loader: () => import('./container/Login/login.js'),
  loading: Loading,
});
const AccountSetting = Loadable({
  loader: () => import('./container/porfilesetting/account.js'),
  loading: Loading,
});

const Activate = Loadable({
  loader: () => import('./container/Activate/activate.js'),
  loading: Loading,
});

const UpdateInfo = Loadable({
  loader: () => import('./container/porfilesetting/updateinfo.js'),
  loading: Loading,
});
const ChangePassword = Loadable({
  loader: () => import('./container/porfilesetting/chnagespassword.js'),
  loading: Loading,
});

// const UpdatePaymentInfo = Loadable({
//   loader: () => import('./container/porfilesetting/paymentmthode.js'),
//   loading: Loading,
// });
const billingHistory = Loadable({
  loader: () => import('./container/porfilesetting/billing.js'),
  loading: Loading,
});
// const VideoInfoNew = Loadable({
//   loader: () => import('./container/VideoInfo/video-info.js'),
//   loading: Loading,
// });

const MyLIst = Loadable({
  loader: () => import('./container/porfilesetting/mylist.js'),
  loading: Loading,
});
// const Watchhistory = Loadable({
//   loader: () => import('./container/porfilesetting/WatchLst.js'),
//   loading: Loading,
// });
// const VIDEO_DATA = Loadable({
//   loader: () => import('./container/Movies/movies.js'),
//   loading: Loading,
// });
// const CATEGORY_DATA = Loadable({
//   loader: () => import('./container/Category/category_list.js'),
//   loading: Loading,
// });
const VIDEO_INFO = Loadable({
  loader: () => import('./container/video_info/video_info.js'),
  loading: Loading,
});
const NotFound = Loadable({
  loader: () => import('./component/NotFound/NotFound.js'),
  loading: Loading,
});
const SEARCH_PAGE = Loadable({
  loader: () => import('./container/search/result.js'),
  loading: Loading,
});
// const COLLECTION_DATA = Loadable({
//   loader: () => import('./component/Tabs/tabs.js'),
//   loading: Loading,
// });
// const VIEW_MORE = Loadable({
//   loader: () => import('./container/ViewMore/ViewMore.js'),
//   loading: Loading,
// });
// const SEASONS_DATA_LIST = Loadable({
//   loader: () => import('./container/ViewMore/Season_data_list.js'),
//   loading: Loading,
// });
// const PLAYLIST_DATA = Loadable({
//   loader: () => import('./container/Playlist/playlist.js'),
//   loading: Loading,
// });
// const PLAYLIST_VIDEOS = Loadable({
//   loader: () => import('./container/Playlist/playlist_video.js'),
//   loading: Loading,
// });
// const USER_PROFILE = Loadable({
//   loader: () => import('./container/profile/profile.js'),
//   loading: Loading,
// });
// const SEASON_EPISODES = Loadable({
//   loader: () => import('./container/seasons/seasons.js'),
//   loading: Loading,
// });
// const TICKET = Loadable({
//   loader: () => import('./container/ticket/ticket.js'),
//   loading: Loading,
// });
const TRANSCATION = Loadable({
  loader: () => import('./container/transaction/transaction.js'),
  loading: Loading,
});
const CONTACTUS = Loadable({
  loader: () => import('./container/contactus/contactus.js'),
  loading: Loading,
});
// const LIVE_TV = Loadable({
//   loader: () => import('./container/LiveTv/LiveTv.js'),
//   loading: Loading,
// });
// const WEBSERIES_LIST = Loadable({
//   loader: () => import('./container/series/SeriesList.js'),
//   loading: Loading,
// });
const SUBSCRIPTION = Loadable({
  loader: () => import('./container/payment/pages/subscription.js'),
  loading: Loading,
});
const INVOICE = Loadable({
  loader: () => import('./container/porfilesetting/invoice.js'),
  loading: Loading,
});
const PAYMENT_REDIRECT = Loadable({
  loader: () => import('./container/payment/pages/index.js'),
  loading: Loading,
});
// const LiveTV = Loadable({
//   loader: () => import('./container/LiveTv/LiveTv'),
//   loading: Loading,
// })
// const VIEWMOREPASS = Loadable({
//   loader: () => import('./container/ViewMore/ViewMorePass.js'),
//   loading: Loading,
// })
const watchHistory = Loadable({
  loader: () => import('./container/porfilesetting/watchhistory.js'),
  loading: Loading,
});
const DeviceList = Loadable({
  loader: () => import('./container/DeviceList/devicelist.js'),
  loading: Loading,
});

const protectedroutes = [
  { path: '/login', exact: true, name: 'login', component: Login },
  { path: '/amrita_search/search_keyword=/amrita@521/:word', exact: false, name: 'Search', component: SEARCH_PAGE },
  { path: '/my-list', exact: true, name: 'signup', component: MyLIst },
  // { path: '/newvideoinfo', exact: true, name: 'videoInfo', component: VideoInfoNew },
  { path: '/update-info', exact: true, name: 'UpdateInfo', component: UpdateInfo },
  { path: '/billing-history', exact: true, name: 'billingHistory', component: billingHistory },
  { path: '/change-password', exact: true, name: 'ChangePassword', component: ChangePassword },
  { path: '/account-setting', exact: true, name: 'AccountSetting', component: AccountSetting },
  { path: '/signup', exact: true, name: 'signup', component: Login },
  { path: '/activate', exact: true, name: 'Activate', component: Activate },
  // { path: '/live', exact: true, name: 'LiveTv', component: LiveTV },
  // { path: '/catchup/:id', exact: true, name: 'CatchUp', component: LiveTV },
  // { path: '/:menu_name/:id', exact: true, name: 'Cat_Page', component: VIDEO_DATA },
  // { path: '/cat/:menu_name/:cat_name/:id', exact: true, name: 'Cat_Page', component: CATEGORY_DATA },
  { path: '/video/cat/:video_name/:video_id', exact: true, name: 'Video', component: VIDEO_INFO },
  { path: '/video/ser/:video_name/:cat_id', exact: true, name: 'Video', component: VIDEO_INFO },
  { path: '/amrita_search/search_keyword=/amrita@521/:word', exact: true, name: 'Search', component: SEARCH_PAGE },
  // { path: '/:menu_name/collectionh/:cat_name/:catId', exact: true, name: 'Collection', component: VIEW_MORE },
  // { path: '/:menu_name/collectionv/:cat_name/:catId', exact: true, name: 'Collection', component: VIEW_MORE },
  // { path: '/:menu_name/collectionhw/:cat_name/:catId', exact: true, name: 'Collection', component: SEASONS_DATA_LIST },
  // { path: '/:menu_name/collectionvw/:cat_name/:catId', exact: true, name: 'Collection', component: SEASONS_DATA_LIST },
  // { path: '/:menu_name/collection/season/:cat_name/:catId', exact: true, name: 'Collection', component: VIEWMOREPASS },
  // { path: '/mycollection', exact: true, name: 'mycollection', component: COLLECTION_DATA },
  // { path: '/playlist', exact: true, name: 'Playlist', component: PLAYLIST_DATA },
  // { path: '/season/:season_name/:season_id', exact: true, name: 'Seasons', component: SEASON_EPISODES },
  // { path: '/series/:seriesname/:catId', exact: true, name: 'series', component: WEBSERIES_LIST },
  // { path: '/playlist/:name/:id', exact: true, name: 'Playlist', component: PLAYLIST_VIDEOS },
  // { path: '/amrita_user/profile/:user_id', exact: true, name: 'UserProfile', component: USER_PROFILE },
  // { path: '/:menu_name/kog_livetv/:id', exact: true, name: 'LiveTv', component: LIVE_TV },
  { path: '/contactus', exact: true, name: 'Playlist', component: CONTACTUS },
  { path: '/watch-history', exact: true, name: 'watchhistory', component: watchHistory },
  // { path: '/ticket', exact: true, name: 'ticket', component: TICKET },
  { path: '/transaction-history', exact: true, name: 'transcation', component: TRANSCATION },
  { path: '/subscription-plans', exact: true, name: 'subscription-plans', component: SUBSCRIPTION },
  { path: '/checkout', exact: true, name: 'checkout', component: PAYMENT_REDIRECT },
  { path: '/transaction/invoice/:orderid', exact: true, name: 'invoice', component: INVOICE },
  { path: '/device-list', exact: true, name: 'device-list', component: DeviceList },
  { path: '*', exact: true, name: 'Not Found', component: NotFound },
];
export default protectedroutes;

