let base_url;
let STRIPE_KEY;
const pathName = window.location.hostname;
if (pathName === 'amritalive.com' || pathName === 'www.amritalive.com') {
  base_url = "https://amritatv-a.planetcast.in";
  STRIPE_KEY = "pk_live_51JATlMSJKqRndEvPp96fL7pq0BnOW6Og0BYN0bWlHRsXsA1cKnbRvIVWQSaHHeAaz7LlUkYimLWAPBl9wcwvhDsT00PX8tmnwL"
}
else {
  //local host and qa amrita
 // base_url = "https://stg-test.planetcast.in";   //stg url
  base_url = "https://amritatv-a.planetcast.in";
  STRIPE_KEY = 'pk_test_51JATlMSJKqRndEvPCIy4KDu0DKzFqNuzSdLWQrUWRPZ3fyBWIKad7cJxldgauYjTsoRdmVRaVpPx7jEGlZiWdsmJ00sefStUQh'
}

// export const DOMURL = 'https://beta.amritatvonline.com' //beta
// export const COOKIEDOM = '.amritatvonline.com' //beta
export const DOMURL = 'https://amritalive.com' //live
export const COOKIEDOM = '.amritalive.com' //live
export const firebaseurl = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBDNT1ht7HpO3HOZAx2uCy3BK1uzmMGoM0'
export const COUNTRYCODE = localStorage.getItem('countrycode') ? localStorage.getItem('countrycode') : 'IN'
export const PARTNER_ID = 'ott615'
export const LOGO = 'https://d299omnc29smln.cloudfront.net/v1/webp_compress/ott615/logo/728621832test2.png'
// export const HLSJSVERSION = '1.0.11'
export const HLSJSVERSION = '1.4.11'
export const HOME_DATA = base_url + '/home'
export const HOMEPOPUP_DATA = base_url + '/home-popup'
export const MOVIES_DATA = base_url + '/category_subdata'
export const SERIESVIEWMORE = base_url + '/category_subdata'
export const LEFT_MENU_DATA = base_url + '/v3/leftmenu'
// export const MENU_DATA = base_url + '/v1/menu'
export const MENU_DATA = base_url + '/v3/menu'
export const VIDEO_DATA = base_url + '/videoinfo'
export const CATEGORY_DATA = base_url + '/category_data'
export const VIEW_MORE_CATEGORY_DATA = base_url + '/category_info'
export const SEASON_DATA = base_url + '/series_subdata'
export const RELATED_VIDEO = base_url + '/relatedvideo'
export const SEARCH = base_url + '/search'
// export const LEFTMENU = base_url + '/leftmenu'
export const LEFTMENU = base_url + '/v3/leftmenu'
export const CONTINUE_WATCHING = base_url + '/continue_watching'
export const FAVOURITES = base_url + '/favourite'
export const WATCHLATER = base_url + '/watch_later';
export const USER_HISTORY = base_url + '/userhistory'
export const VIEW_MORE = base_url + '/category_data'
// export const LOGIN=base_url+'/login_otp';
export const LIVEFEED = base_url + '/livefeed'
export const LOGIN = base_url + '/v3/login';
//export const SIGNUP = base_url + '/v1/signup';
export const SIGNUP = base_url + '/v3/sign-up';
export const SIGNUPVERIFY = base_url + '/sign-up-verify';
export const FORGETVERIFY = base_url + '/reset-web-password';
// new-forgot-password
export const FORGOT_PASSWORD_WITH_LOGIN = base_url + '/new-forgot-password';
export const FORGOT_PASSWORD = base_url + '/forgot_password';
export const RESET_PASSWORD = base_url + '/reset_password';
export const LOGOUT = base_url + '/logout';
export const MULTIDEVICELOGOUT = base_url + '/multiple-logout ';
export const DEVICELISTING = base_url + '/device-listing';
export const USER_DETAILS = base_url + '/login';
// export const USER_PROFILE = base_url + '/profile_update'
export const USER_PROFILE = base_url + '/profile_update'
export const UPLOAD_PROFILE = base_url + '/upload'
export const GET_TOKEN = base_url + '/gettoken';
export const PLAYLIST_VIEW = base_url + '/playlistview'
export const CHANGE_PASSWORD_WITHLOGIN = base_url + '/new-change-password'
//new-forgot-password
export const PLAYLIST = base_url + '/playlist'
export const PLAYLIST_METADATA = base_url + '/playlistmetadata';
export const GENERATE_TICKET = base_url + '/ticket';
export const TRANSCATION = base_url + '/transaction-history';
export const TICKET_HISTORY = base_url + '/ticket_history'
export const LIKEDISLIKE = base_url + '/like'
export const WEBSERIES = base_url + '/webseries'
export const PLANLIST = base_url + '/plan-details'
export const INITIATEORDER = base_url + '/response';
export const PAYMENT = base_url + '/v1/app_payment';
export const PAYMENT_INFO = base_url + '/pay_info';
export const PAY_PER_VIEW = base_url + '/user_wallet';
export const USERID = localStorage.getItem('userid') == null ? 0 : localStorage.getItem('userid')

function writeCookie(key, value) {
  window.document.cookie = key + "=" + value + "; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
  return value;
};
const randonUuid = Date.now().toString(36) + Math.random().toString(36).substring(2);
if (!getCookie("_uuid")) writeCookie("_uuid", randonUuid);

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
localStorage.setItem('uuid', getCookie("_uuid"))

export const UUID = localStorage.getItem("uuid")
export const CATCHUP = base_url + '/catchup'
export const tvcodeVerify = base_url + '/code-verify';
// export const PAYMENTINTENT_API = base_url + '/payment-intent'
export const PAYMENTINTENT_API = base_url + '/stripe-subscriptions'
export const CANCELMEMBERSHIP_API = base_url + '/stripe-cancel'
export const GETTRANSACTIONPI = base_url + '/get-transaction'
export const UPGRADEPLAN_API = base_url + '/upgrad-plan'
export const INVOICEDETAILS_API = base_url + '/invoice-details'
export const STRIPEPRODUCTION_KEY = STRIPE_KEY;
export const deeplinkurl = 'https://amritatv.page.link/?link=https://beta.amritatvonline.com/&apn=com.AmritaLiveTV.activity&amv=12&ibi=com.amritalive.ios&isi=1559510636&ius=com.amritalive.ios'
export const slideBy = 5
export const errMrssage = "Your session is expire please re-login"
export const TOKENVALIDATE = base_url + "/login-token-verify"
export const COUNTRYLIST = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D\'Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People\'S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
]


