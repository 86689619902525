import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import Social from '../Social/Social.js';
import $ from "jquery";
import axios from "axios";
import { MENU_DATA } from "../../url";
class Footer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            footermenu: [],
            isLoading: false,
            windowWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
        }
    }
    componentDidMount() {
        // alert('propsfooter'+JSON.stringify(this.props.data))
        window.addEventListener('resize', this.updateDimensions);

        // detect android and ios webview code start
        const navigator = window.navigator;
        const userAgent = navigator.userAgent;
        const normalizedUserAgent = userAgent.toLowerCase();
        const standalone = navigator.standalone;

        const isIos = (/ip(ad|hone|od)/.test(normalizedUserAgent) || navigator.platform === 'MacIntel') && navigator.maxTouchPoints > 1;
        const isAndroid = /android/.test(normalizedUserAgent);
        const isSafari = /safari/.test(normalizedUserAgent);
        const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

        // const osText = isIos ? 'iOS' : isAndroid ? 'Android' : 'Other';
        // const webviewText = isWebview ? 'Yes' : 'No';

        // detect android and ios webview code end
        setTimeout(() => {
            if(!isWebview) this.getfooterMenu()
        }, 1000);

        // window.scrollTo(0, 0)
    }
    componentDidUpdate() {
        // window.scrollTo(0, 0)
    }
    updateDimensions = () => {
        this.setState({ windowWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 100 });
    };
    getfooterMenu = async () => {
        //  // // debugger
        let formData = new FormData();
        formData.append("type", "l");
        let response = await axios.post(MENU_DATA, formData, {
            headers: { 'token': localStorage.getItem('token') },
        });
        if (response.status === 200) {
            //    // // debugger
            let result = response.data;
            //  // // debugger
            if (result.other.length !== undefined && result.other !== null && result.other !== '' && result.other.length > 0) {
                this.setState({
                    footermenu: result.other,
                    isLoading: true
                });
                //setTimeout(() => this.setState({ isLoading: true }), 500)
                sessionStorage.setItem("user_status", JSON.stringify(result.update_status))
            }
        }
        else {
            let msg = response.data.message;
            $("#snackbar .msg").html(msg);
            $("#snackbar")
                .addClass("show")
                .addClass("bg-danger")
                .removeClass("bg-success");
            setTimeout(function () {
                $("#snackbar").removeClass("show");
                // localStorage.clear();
                // window.location.href = "/";
            }, 2000);
        }
    };
    render() {
        const { isLoading } = this.state;
        // console.log('footermenu', this.state.footermenu)
        if (isLoading) {
            return (

                <footer className="home_footer">
                    <div className="container2" style={{ width: this.state.windowWidth }} >
                        <div className='col-md-12 res-padding0'>
                            <div className="row">
                                <div className="col-xl-6  col-lg-5 col-md-6 d-flex align-items-center part1 res-full box p-0 ">
                                    <ul className="links">
                                        {
                                            this.state.footermenu !== undefined && this.state.footermenu !== null && this.state.footermenu !== '' && this.state.footermenu.map((item, i) => {
                                                return (
                                                    <li>
                                                        {/* <a exact={true} href={"/" + item.slug} className="border-effect">{item.catName}</a> */}
                                                        <Link to={"/" + item.slug} className="border-effect">{item.catName}</Link>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                                <div className="col-xl-6  col-lg-7 col-md-6 d-flex align-items-center justify-content-end flex-wrap part2 res-full box p-0 ">
                                    <Social />
                                    <div className="app-section ml-2">
                                        <ul className="list list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a href="https://play.google.com/store/apps/details?id=com.AmritaLiveTV.activity" target="_blank">
                                                    <div className="icon mr-2">
                                                        <i className="fa fa-android" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="icn-txt">
                                                        <p className="mb-0">Download from</p>
                                                        <h4 className="mb-0">Google Play</h4>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://apps.apple.com/us/app/amrita-live/id1559510636" target="_blank">
                                                    <div className="icon mr-2">
                                                        <i className="fa fa-apple" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="icn-txt">
                                                        <p className="mb-0">Download from</p>
                                                        <h4 className="mb-0">App Store</h4>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            )
        }
        else {
            return (<></>)
        }
    }
}
export default Footer;
