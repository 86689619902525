import React, { useRef } from 'react';
class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.keywords = React.createRef();
  }

  state = { keyword: '' }
  componentDidMount = () => {
    if (localStorage.getItem('token') && localStorage.getItem('userid')) {
      document.body.classList.add('darkHdr');
      document.body.classList.add('topspace');
      document.body.classList.remove('heardr-rmvo');
    }
  }

  getSearchData = async (e) => {
    //debugger
    e.preventDefault();
    //  alert('search result',e.target.keyword.value)
    //  console.log("propsdata", this.props)
    //let keywordvalue = e.target.keyword.value
    let keywordvalue = this.keywords.current.value

    // // debugger
    if (keywordvalue !== undefined && keywordvalue !== null && keywordvalue !== '' && keywordvalue !== '//' && keywordvalue !== '%' && keywordvalue !== '#') {
      // return false;
      let location = `/amrita_search/search_keyword=/amrita@521/${keywordvalue.trim()}`
      window.location.href = location
      return false
    }
    else {
      // alert('nahi chala')
    }
    //  if(keywordvalue.trim() ==='' || keywordvalue.trim() ==="/" || keywordvalue.trim() ==="?"){
    //   alert('nahi chala')
    //   // return false;
    //  }
    //  let location= `/amrita_search/search_keyword=/amrita@521/${keywordvalue}`
    //  window.location.href =location
    // console.log('this.props.history::',this.props.history)
    //  this.props.history.push(`/amrita_search/search_keyword=/amrita@521/${e.target.keyword.value}`)
  }


  // blur=(e)=>{
  //   e.target.value="";
  // }
  render() {
    //alert('keyword',this.state.keyword)
    return (
      <form onSubmit={this.getSearchData} className="search-form">

        <div className="form-group has-feedback" style={{ display: 'flex' }}>
          <button type="submit" onClick={this.getSearchData}>
            <i className="glyphicon fa fa-search glyphicon-search form-control-feedback"></i></button>
          <input
            type="text"
            className="form-control"
            name="keyword" value={this.state.keyword} onChange={(value) => { this.setState({ keyword: this.value }) }}
            placeholder="Search..."
            ref={this.keywords}
          />
        </div>
      </form>
    )
  }
}
export default SearchComponent;
