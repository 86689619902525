import React from 'react';
import Loadable from 'react-loadable';

function Loading() {
  return <div></div>;
}
const HOME = Loadable({
  loader: () => import('./container/Home/home.js'),
  loading: Loading,
});
const COMMON = Loadable({
  loader: () => import('./container/Common/common.js'),
  loading: Loading,
});
// const SEARCH_PAGE = Loadable({
//   loader: () => import('./container/search_result/search_result.js'),
//   loading: Loading,
// });
// const VIEW_MORE = Loadable({
//   loader: () => import('./container/ViewMore/ViewMore.js'),
//   loading: Loading,
// });
const CONTACTUS = Loadable({
  loader: () => import('./container/contactus/contactus.js'),
  loading: Loading,
});
// const LiveTV = Loadable({
//   loader: () => import('./container/LiveTv/LiveTv'),
//   loading: Loading,
// })
const ABOUTUS = Loadable({
  loader: () => import('./container/pages/aboutUs'),
  loading: Loading,
});
const TREMS = Loadable({
  loader: () => import('./container/pages/trems'),
  loading: Loading,
});
const FAQ = Loadable({
  loader: () => import('./container/pages/faqs'),
  loading: Loading,
});
const PRIVACY = Loadable({
  loader: () => import('./container/pages/privacypolicy'),
  loading: Loading,
});
const REFUNDPOLICY = Loadable({
  loader: () => import('./container/pages/refundpolicy'),
  loading: Loading,
});
const SUBSCRIPTIONPOLICY = Loadable({
  loader: () => import('./container/pages/subscriptionpolicy'),
  loading: Loading,
});
const SUPPORT = Loadable({
  loader: () => import('./container/pages/support'),
  loading: Loading,
});
// const VIEWMOREPASS = Loadable({
//   loader: () => import('./container/ViewMore/ViewMorePass.js'),
//   loading: Loading,
// })
const Help = Loadable({
  loader: () => import('./container/porfilesetting/help.js'),
  loading: Loading,
});

const SUBSCRIPTION = Loadable({
  loader: () => import('./container/payment/pages/subscription.js'),
  loading: Loading,
});

const INVOICE = Loadable({
  loader: () => import('./container/porfilesetting/invoice.js'),
  loading: Loading,
});

const VIEWALL = Loadable({
  loader: () => import('./container/viewAll/index.js'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const publicroutes = [
  { path: '/', exact: true, name: 'Home', component: HOME },
  { path: '/:menuname/:catid', exact: true, name: 'page', component: COMMON },
  // { path: '/live/:id', exact: true, name: 'LiveTv', component: LiveTV },
  { path: '/about-us', exact: true, name: 'aboutus', component: ABOUTUS },
  { path: '/faq', exact: true, name: 'faqs', component: FAQ },
  { path: '/privacy-policy', exact: true, name: 'privacypolicy', component: PRIVACY },
  { path: '/subscription-policy', exact: true, name: 'subscriptionpolicy', component: SUBSCRIPTIONPOLICY },
  { path: '/refundpolicy', exact: true, name: 'refundpolicy', component: REFUNDPOLICY },
  { path: '/support', exact: true, name: 'support', component: SUPPORT },
  { path:'/subscription',exact:true,name: 'subscription-plans',  component:SUBSCRIPTION },
  { path: '/mtransaction/invoice/:orderid', exact: true, name: 'minvoice', component: INVOICE },
  { path: '/help', exact: true, name: 'support', component: Help },
  { path: '/contact-us', exact: true, name: 'contact us', component: CONTACTUS },
  { path: '/terms-of-use', exact: true, name: 'terms-of-use', component: TREMS },
  { path: '/viewall/:cat_name/:catId', exact: true, name: 'viewall', component: VIEWALL },
  // { path: '/viewall/:ser_name/:serId', exact: true, name: 'viewall', component: VIEWALL }
];
export default publicroutes;
