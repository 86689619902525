import React from 'react';
import logo from './logo.svg';
import ReactDOM from 'react-dom';
import './App.css';
import { BrowserRouter, Router, Link, Route, Switch, HashRouter, Redirect } from "react-router-dom";
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import protectedroutes from './ProtectedRoutes.js';
import axios from 'axios';
import { COUNTRYCODE, PARTNER_ID, USERID, LEFTMENU } from './url';
import $ from 'jquery';
import Auth from './Auth'

class App extends React.Component {
  state = { keyword: '', leftMenu: [] }
  componentDidMount() {
    if (localStorage.getItem('token')) {
      //this.getData();
    }
  }
  getData = async () => {
    // const token={headers:{token:USERID}}
    var formData = new FormData();
    formData.append('userid', USERID);
    formData.append('countrycode', COUNTRYCODE);
    formData.append('partnerid', PARTNER_ID);
    let response = await axios.post(LEFTMENU, formData, { headers: { 'token': localStorage.getItem('token') } })
    if (response.status === 200 && response.data.status !== 101) {
      //this.setState({leftMenu:response.data.leftMenu})
    }
    else {
      let msg = response.data.message;
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');
        // localStorage.clear();
        // window.location.href = "/"
      }, 2000);
    }
  }
  render() {
    return (
     <>
     <ToastContainer autoClose={2000} />
        <Switch>
          {protectedroutes.map((route, idx) => {
            return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
              <route.component {...props} />
            )} />)
              : null;
          },
          )}
        </Switch>
     </>
    );
  }
}
export default Auth(App);
