import React from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { LEFT_MENU_DATA, MENU_DATA, PARTNER_ID, COUNTRYCODE, LOGOUT, UUID, GET_TOKEN, errMrssage } from "../../url";
import Search from "../../container/search/search";
import { toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../component/loader/home_loading.js";
import userProfle from '../../assests/images/usr-prfl-icn.png'
import app_icon from '../../assests/images/app_icon.png';
import swal from "sweetalert";

const Zoom = cssTransition({ enter: "zoomIn", exit: "zoomOut", duration: 750, });
let isAuth = localStorage.getItem('userid');
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.header = React.createRef();
  }
  state = { letmenuItems: [], menuItems: [], keyword: "", showModal: false, loading: false, error: null, window_width: window.screen.width, detect_device: "", isLoading: false, logoUrl: '', windowWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 50, windowUrl: '', paymentbuttontext: '' };
  isIOSDevice() { return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform); }
  componentDidMount() {
    // detect android and ios webview code start
    const navigator = window.navigator;
    const userAgentwv = navigator.userAgent;
    const normalizedUserAgent = userAgentwv.toLowerCase();
    const standalone = navigator.standalone;

    const isIos = (/ip(ad|hone|od)/.test(normalizedUserAgent) || navigator.platform === 'MacIntel') && navigator.maxTouchPoints > 1;
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

    // const osText = isIos ? 'iOS' : isAndroid ? 'Android' : 'Other';
    // const webviewText = isWebview ? 'Yes' : 'No';

    // detect android and ios webview code end
    setTimeout(() => {
      // console.log('This will run after 1 second!')
      // alert("outer"+isWebview)
      if (!isWebview) {
        // alert("inner"+isWebview)
        this.getData()
        this.getMenuleft()
      } else {
        this.setState({ isLoading: true });
      }
    }, 1000);

    const url_ = window.location.href;
    const n = url_.lastIndexOf("/");
    const result = url_.substring(n + 1);
    this.setState({ windowUrl: result });
    this.load();
    // this.getToken();
    //detecting device ios and android
    window.addEventListener('scroll', this.handleScroll);
    let userAgent = navigator.userAgent.toLowerCase();
    let Android = userAgent.indexOf("android") > -1;
    if (Android) {
      this.setState({ detect_device: "Android" });
    }
    if (this.isIOSDevice()) {
      this.setState({ detect_device: "IOS" });
    }

    window.addEventListener("scroll", () => {
      const position = window.pageYOffset;
      if (position > 10) {
        this.header.current.classList.add('drk-hdr');
      }
      else {
        this.header.current.classList.remove('drk-hdr');
      }
    });
    //set container width when resize of window
    window.addEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ windowWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 50 });
  };

  openModal() {
    this.setState({
      showModal: true,
    });
  }
  closeModal() {
    this.setState({ showModal: false, error: null, });
  }
  onLoginSuccess(method, response) {
    // console.log("logged successfully with " + method);
  }
  onLoginFail(method, response) {
    // console.log("logging failed with " + method);
    this.setState({
      error: response,
    });
  }
  startLoading() {
    this.setState({
      loading: true,
    });
  }
  finishLoading() {
    this.setState({
      loading: false,
    });
  }
  afterTabsChange() {
    this.setState({
      error: null,
    });
  }
  getMenuleft() {
    let formData = new FormData();
    // formData.append("type", "h");
    axios.post(LEFT_MENU_DATA, formData, {
      headers: { 'token': localStorage.getItem('token') },
    })
      .then(response => {
        let ins = this
        if (response.status === 200) {
          // //  // // debugger
          let result = response.data;
          let name = response.data.user_name;
          let number = response.data.user_mobile;
          localStorage.setItem('user_name', name)
          localStorage.setItem('user_mobile', number)
          if (result.leftMenu.length !== undefined && result.leftMenu !== null && result.leftMenu !== '' && result.leftMenu.length > 0) {
            // //  // // debugger
            ins.setState({
              letmenuItems: result.leftMenu,
              isLoading: true
            });
          }
        }
      })
      .catch(error => {
        // localStorage.clear();
        // window.location.reload()
        console.error('There was an error!', error);
      });
  }

  getData = async () => {
    let formData = new FormData();
    formData.append("type", "h");
    let response = await axios.post(MENU_DATA, formData, {
      headers: { 'token': localStorage.getItem('token') },
    });
    let result = response.data;
    if (result.status === 101) {
      swal({
        title: "Oops!",
        text: errMrssage,
        type: "error",
        icon: "error",
      }).then(function () {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/"
      });
    }
    else {
      //console.log("menudata",result.Menu)
      if (result.Menu) {
        console.log('Menu', result.Menu)
        this.setState({ logoUrl: result.logo_url, menuItems: result.Menu, isLoading: true });
        //setTimeout(() => this.setState({ isLoading: true }), 500)
        // sessionStorage.setItem("user_status", JSON.stringify(result.update_status))
        if (isAuth !== undefined && isAuth !== null && isAuth !== '' && JSON.stringify(result.user_plan_status) === "0" && JSON.stringify(result.plan_expiry_status) === "0") {
          this.setState({ paymentbuttontext: "Subscribe" });
        } else if ((isAuth !== undefined && isAuth !== null && isAuth !== '') && ((JSON.stringify(result.user_plan_status) === "1" && JSON.stringify(result.plan_expiry_status) === "1") || (JSON.stringify(result.user_plan_status) === "0" && JSON.stringify(result.plan_expiry_status) === "1"))) {
          this.setState({ paymentbuttontext: "Renew" });
        } else {
          this.setState({ paymentbuttontext: "" });
        }
        sessionStorage.setItem("user_planstatus", JSON.stringify(result.user_plan_status))
        sessionStorage.setItem("plan_expiry_status", JSON.stringify(result.plan_expiry_status))
        localStorage.setItem('logoUrl', result.logo_url)
      }
      let currentlocation = window.location.pathname
      let menu_datamain = result.Menu
      if (result.plan_expiry_status !== undefined && result.plan_expiry_status) {
        let msg = result.plan_expiry_message
        $("#snackbar .msg").html(msg);
        $("#snackbar")
          .addClass("show")
          .addClass("bg-danger")
          .removeClass("bg-success");
        setTimeout(function () {
          $("#snackbar").removeClass("show").removeClass("bg-danger");
          //this.props.history.push("/");
        }, 3000);
      }
      else if (result.planEligibility === 1 && currentlocation === '/') {
        localStorage.setItem('theme', result.planEligibility)
        for (let i = 0; i < menu_datamain.length; i++) {
          let theme_type = menu_datamain[i].theme_type;
          if (theme_type === 1) {
            let catName = menu_datamain[i].catName;
            let catID = menu_datamain[i].catID;
            let locationreload = `/${catName.replace(/\s/g, "").toLowerCase()}/${catID}`;
            localStorage.setItem('ecpath', locationreload)
            //console.log('locationreload', locationreload)
            window.location.href = locationreload
          }
        }
      } else {
        for (let i = 0; i < menu_datamain.length; i++) {
          let theme_type = menu_datamain[i].theme_type;
          if (theme_type === 1) {
            localStorage.setItem('theme', theme_type)
            let catName = menu_datamain[i].catName;
            let catID = menu_datamain[i].catID;
            let locationreload = `/${catName.replace(/\s/g, "").toLowerCase()}/${catID}`;
            localStorage.setItem('ecpath', locationreload)
          }
        }
      }
    }
    // else {
    //   let msg = response.data.message;
    //   $("#snackbar .msg").html(msg);
    //   $("#snackbar")
    //     .addClass("show")
    //     .addClass("bg-danger")
    //     .removeClass("bg-success");
    //   setTimeout(function () {
    //     $("#snackbar").removeClass("show");
    //   }, 2000);
    // }
  };
  getToken = async () => {
    let tokenVal = localStorage.getItem('token')
    let formData1 = new FormData();
    formData1.append('partnerid', PARTNER_ID);
    if (!tokenVal) {
      await axios.get(GET_TOKEN, formData1).then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.Token)
          this.setState({ isLoading: true })
        }
      }).catch((error) => {
        // console.log(error);
      })
    }

  }
  logOut = () => {
    let formData = new FormData();
    formData.append("userid", localStorage.getItem("userid"));
    formData.append("countrycode", COUNTRYCODE);
    formData.append("partnerid", PARTNER_ID);
    formData.append("uuid", UUID);
    axios
      .post(LOGOUT, formData, {
        headers: { 'token': localStorage.getItem('token') },
      })
      .then((response) => {
        toast("Logging Out Please wait", { transition: Zoom });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  close_download_app_strip = () => {
    document.getElementById("download_app_section_header").style.display = "none";
  };
  load = () => {
    // let clientId = document.getElementById('header');
    // //clientId.classList.add("drk-hdr")
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if ((window.scrollY) > window.innerHeight / 100) {

    }
    else {
    }
  }
  DrpdwnClick = (e) => {
    let clientId = document.getElementById('acntdrplst');
    e.currentTarget.parentNode.classList.toggle("dwn-aro")
    clientId.classList.toggle("drpdwnlst")
  }
  MenuOpen = (e) => {
    let MenuCls = document.querySelector('.Nav-menu-wppr');
    let Ovrly = document.querySelector('.ovrLay');
    MenuCls.classList.add("opn-menu")
    Ovrly.classList.add("OpnLayBx")
  }
  RmovPop = () => {
    let MenuRmv = document.querySelector('.drpdwn-dshbrd-wppr');
    MenuRmv.classList.remove("opn-menu")
  }
  RmoveMenu = (e) => {
    let MenuRmv = document.querySelector('.Nav-menu-wppr');
    let OvrlyRmv = document.querySelector('.ovrLay');
    MenuRmv.classList.remove("opn-menu")
    OvrlyRmv.classList.remove("OpnLayBx")
  }
  PrflPopMenu = (e) => {
    e.stopPropagation()
    let MenuRmv = document.querySelector('.drpdwn-dshbrd-wppr');
    MenuRmv.classList.toggle("opn-menu")
  }
  RmovePrflPop = (e) => {
    e.stopPropagation()
    let MenuRmv = document.querySelector('.drpdwn-dshbrd-wppr');
    MenuRmv.classList.remove("opn-menu")
  }
  goHome() {
    window.location.assign('/')
  }

  reload = () => {
    window.location.reload();
  }

  render() {
    const { menuItems, logoUrl, isLoading, paymentbuttontext } = this.state;
    const menuInline = menuItems && menuItems.length > 0 && menuItems.map((result, key) => {
      return (
        key <= 2 ? <li key={key} className={this.state.windowUrl != "" ? '' : key === 0 ? 'act' : ''} onClick={this.reload}>
          <NavLink exact={true} to={`/${result.catName.replace(/\s/g, "").toLowerCase()}/${result.catID}`} key={key} onClick={this.RmoveMenu} > {`${result.catName}`}</NavLink>
        </li> : ''
      )
    });

    const menuDropdown = menuItems.length > 0 && menuItems.map((result, key) => {

      return (
        key >= 3 ? <li key={key}>
          <NavLink to={`/${result.catName.replace(/\s/g, "").toLowerCase()}/${result.catID}`} key={key} onClick={this.RmoveMenu} >{`${result.catName}`}</NavLink>
        </li> : ''
      )
    });

    if (isLoading) {
      // console.log('letmenuItems::', this.state.letmenuItems)
      return (
        <header id="header" className="header" ref={this.header} onClick={this.RmovPop}>
          <div className="ovrLay" onClick={this.RmoveMenu}></div>
          <div className="top-hdr-wppr d-flex align-items-center justify-content-between transition h-auto p-0"  >
            <div className="container px-0" style={{ width: this.state.windowWidth }}>
              <div className="row">
                {window.location.pathname === '/' && this.state.window_width < 767 && (
                  <div id="download_app_section_header" style={{ width: '100%' }}>
                    <div className="download_app_section_header d-flex align-items-center w-100">
                      <a onClick={this.close_download_app_strip}>
                        <CloseIcon />
                      </a>
                      <div className="d-flex align-items-center ml-2">
                        <span className="icon">
                          <img src={app_icon} alt="" />
                        </span>
                        <span className="ml-2">DOWNLOAD THE APP</span>
                      </div>

                      {this.state.detect_device === "Android" && (
                        <a
                          href="https://play.google.com/store/apps/details?id=com.AmritaLiveTV.activity"
                          className="btn ml-auto"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Install Now
                        </a>
                      )}
                      {this.state.detect_device === "IOS" && (
                        <a
                          href="https://apps.apple.com/us/app/amrita-live/id1559510636"
                          className="btn ml-auto"
                          rel="noopener noreferrer"
                          target="_blank">Install Now</a>
                      )}
                    </div>
                  </div>
                )}
                <div className="Hdr-mnu-wppr " style={{ padding: '20px' }}>
                  <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12">
                    <div className="top-menu-wppr d-flex align-items-center">
                      <div className="logo-wppr">
                        <div className="logo-bx" onClick={this.goHome}>
                          <NavLink to="/">
                            <img src={logoUrl} alt="" />
                          </NavLink>
                        </div>
                      </div>
                      <div className="Nav-menu-wppr">
                        <div className="cls-menu-wppr">
                          <button type="button" className="cls-menu-btn" onClick={this.RmoveMenu}></button>
                        </div>
                        <div className="Nav-logo">
                          <NavLink to="/" onClick={this.RmoveMenu}>
                            <img src={logoUrl} alt="" />
                          </NavLink>
                        </div>
                        <nav>
                          <ul>
                            {menuInline}
                            {menuItems.length > 3 &&
                              <li className="drpdwn-wppr">
                                <Link to={void (0)}>Categories</Link>
                                <div className="drpdwn-menu">
                                  <div className="title">
                                    <ul>
                                      {menuDropdown}
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
                    <div className="login-wppr">
                      {
                        paymentbuttontext && <div className="vwrPrmtn">
                          <ul>
                            <li><Link to='/subscription-plans'> <b style={{ paddingLeft: "15px", paddingRight: "15px" }}>{paymentbuttontext}</b></Link></li>
                          </ul>
                        </div>
                      }

                      <div className="srch-wppr">
                        <Search {...this.props} />
                      </div>
                      <div className="usr-login-wppr">
                        <div className="usr-icn">
                          <span onClick={this.PrflPopMenu}><i className="fa fa-user-o " aria-hidden="true"></i></span>
                          {
                            isAuth !== undefined && isAuth !== null && isAuth !== '' ? <div className="drpdwn-dshbrd-wppr">
                              <div className="dshbrd-prfl-pop">
                                <div className="title">
                                  <div className="usr-img">
                                    <span >
                                      <img src={userProfle} alt="User Profile" />
                                    </span>
                                  </div>
                                  <ul>
                                    <li>
                                      <h5>{localStorage.getItem('user_name') !== undefined && localStorage.getItem('user_name') !== 'null' && localStorage.getItem('user_name') !== null ? localStorage.getItem('user_name') : ''}</h5>
                                    </li>
                                    <li>
                                      <p>
                                        {localStorage.getItem('user_name_val') !== undefined && localStorage.getItem('user_name_val') !== 'null' && localStorage.getItem('user_name_val') !== null ? localStorage.getItem('user_name_val') : ''}
                                      </p>
                                    </li>
                                    <li>
                                      <p>
                                        {localStorage.getItem('user_mobile') !== undefined && localStorage.getItem('user_mobile') !== 'null' && localStorage.getItem('user_mobile') !== null ? localStorage.getItem('user_mobile') : ''}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="drpdwn-dshbrd-lst-itm">
                                <div className="title">
                                  <ul>
                                    {
                                      this.state.letmenuItems !== undefined && this.state.letmenuItems !== null && this.state.letmenuItems.length > 0 && this.state.letmenuItems.map((item, i) => {
                                        return <li><a href={`/${item.slug.replace(/\s/g, "").toLowerCase()}`} onClick={this.RmovePrflPop} >{item.catName}</a></li>
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                              <div className='singout-btn-wppr'>
                                <button type='button' className="logout-btn" onClick={this.logOut}>Sign Out</button>
                              </div>
                            </div> : <div className="drpdwn-dshbrd-wppr">

                              <div className="drpdwn-dshbrd-lst-itm">
                                <div className="title">
                                  <ul>
                                    {
                                      this.state.letmenuItems !== undefined && this.state.letmenuItems !== null && this.state.letmenuItems.length > 0 && this.state.letmenuItems.map((item, i) => {
                                        return <li><Link to={`/` + item.slug} onClick={this.RmovePrflPop}>{item.catName}</Link></li>
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                              <div className='singout-btn-wppr pb-1' onClick={this.RmovePrflPop}>
                                <Link to='/login'>
                                  <button type='button' className="logout-btn">Sign In</button>
                                </Link>
                              </div>
                              <div className='singout-btn-wppr' onClick={this.RmovePrflPop}>
                                <Link to='/signup'>
                                  <button type='button' className="logout-btn">Sign Up</button>
                                </Link>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="menu-icn-wppr">
                        <div className="menu-icn" onClick={this.MenuOpen} >
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
    } else {
      return (<Loader />)
    }
  }
}
export default Header;