import React,{useState} from 'react';
const  useCounter=()=>{
    const load = () => {
        let clientId = document.getElementById('header');
        let FtrBgrnd = document.querySelector('.home_footer');
        let clientHeight = clientId.offsetHeight
        document.getElementById("main").style.marginTop = clientHeight + "px";
        clientId.classList.add("drk-hdr")
        FtrBgrnd.classList.remove("gry_bg"); 
    }

    return {
        load
    }
}

export default useCounter;