import React from 'react';
import Login from "./container/Login/login.js"
// import NotFound from './component/not_found/not_found'
const Auth = (OldComponent) => {
  class NewComponent extends React.Component {
    render() {
      //console.log(this.props)
      if (localStorage.getItem('token') && localStorage.getItem('userid')) {
        return (<OldComponent {...this.props} />)
      }
      if (localStorage.getItem('token') && !localStorage.getItem('userid')) {
        return (<Login  {...this.props} />)
      }

      else {
        if (localStorage.getItem('token')) {
          return (<OldComponent {...this.props} />)
        }
        else {
          return (<Login  {...this.props} />)
        }
      }
    }
  }
  return NewComponent;
}
export default Auth;
